<div class="content_main-container">
  <pbb-lang-toggle></pbb-lang-toggle>
  <h2 class="mb-8 text-dark" id="cookie_prompt_title">{{ 'COOKIE.PROMPT.TITLE' | translate }}</h2>
  <div class="mb-32 prewrap">
    <p class="mat-headline-4 text-grey-subtitle" id="cookie_prompt_desc1">{{ 'COOKIE.PROMPT.DESCRIPTION' | translate }}</p>
    <p class="mat-headline-4 text-desc mt-8" id="cookie_prompt_desc_more">
      <span
        ><a
          href="{{ 'COOKIE.LINKS.TNC.URL' | translate }}"
          target="_blank"
          rel="noopener"
          class="text-underlined cookie-terms"
          >{{ 'COOKIE.LINKS.TNC.LINK' | translate }}</a
        >
      </span>
    </p>
  </div>
  <div class="content_main-container-button-list">
    <div
      class="flex"
      [ngClass]="
        isMobile
          ? 'content_main-button-list-cookie-actions-mobile flex-around'
          : 'content_main-button-list-cookie-actions flex-end'
      "
    >
      <button mat-flat-button color="accent" id="cookie_prompt_manage" (click)="manageCookies()">
        {{ 'COOKIE.PROMPT.MANAGE_SETTINGS' | translate }}
      </button>
      <button mat-flat-button color="primary" id="cookie_prompt_accept" (click)="acceptCookies()">
        {{ 'COOKIE.PROMPT.ACCEPT_ESSENTIAL' | translate }}
      </button>
    </div>
  </div>
</div>
