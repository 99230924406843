<div #noticeElem class="notice" [ngClass]="class" [class.inline]="!inline">
  <div class="notice_icon-container">
    <mat-icon [ngStyle]="{ color: iconColor }">{{ icon }}</mat-icon>
  </div>
  <div class="notice-container">
    <div class="notice_title" [innerHtml]="title | translate" role="status" aria-live="polite"></div>
    <div class="notice_subtitle" [innerHtml]="subtitle" role="status" aria-live="polite"></div>
  </div>
  <button
    mat-icon-button
    class="notice_icon-container btn-icon-slim"
    *ngIf="dismissable"
    (click)="dismiss()"
    [attr.aria-label]="'ARIA_LABEL.CLOSE' | translate"
  >
    <mat-icon class="icon-sm">close</mat-icon>
  </button>
</div>
